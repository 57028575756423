import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { CONST_SECTION_IDS } from '../../constants';

import SectionHeaderText from '../standards/SectionHeaderText';
import StandardParagraph from '../standards/StandardParagraph';
import TextWithAlignSideImage from '../elements/TextWithAlignSideImage';
import TabSectionContainer from '../elements/TabSectionContainer';
import ThreeImagesSection from '../elements/ThreeImagesSection';
import ReadMore from '../elements/ReadMore';

import fullContent from '../../content/mainContent.yaml';

const mainContent = fullContent.methodsOfPhysiotherapySection;
const imagesAlts = mainContent.images.alts;

const MethodsOfPhysiotherapySection = () => {
  const imagesQuery = useStaticQuery(graphql`
    query {
      readMoreImages: allFile(
        filter: { relativeDirectory: { eq: "methods-of-physiotherapy-adds" } }
        sort: { fields: childImageSharp___sizes___originalName, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      mainImages: allFile(
        sort: { fields: childImageSharp___sizes___originalName, order: ASC }
        filter: { relativeDirectory: { eq: "methods-of-physiotherapy" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section
      className="standard-section section-methods-of-physiotherapy"
      id={CONST_SECTION_IDS.methodsOfPhysiotherapy}
    >
      <TabSectionContainer
        sectionTitle={mainContent.title}
        customized="physiotherapy"
        openerSectionId={CONST_SECTION_IDS.methodsOfPhysiotherapy}
      >
        <div className="standard-section standard-container">
          <SectionHeaderText title={mainContent.mainContent.title} />
          <div className="standard-section-container col-3-with-separator">
            <div>
              <StandardParagraph text={mainContent.mainContent.paragraphs[0]} />
              <StandardParagraph text={mainContent.mainContent.paragraphs[1]} />
            </div>
            <div className="separator"></div>
            <div>
              <StandardParagraph text={mainContent.mainContent.paragraphs[2]} />
              <StandardParagraph text={mainContent.mainContent.paragraphs[3]} />
            </div>
            <div className="separator"></div>
            <div>
              <StandardParagraph text={mainContent.mainContent.paragraphs[4]} />
            </div>
          </div>
          <ReadMore scrollToElement="#metody">
            <>
              <SectionHeaderText title={mainContent.readMoreContent.fullWidthSection.title} />
              {mainContent.readMoreContent.fullWidthSection.paragraphs.map((el, index) => {
                return <StandardParagraph text={el} key={index} />;
              })}
              {mainContent.readMoreContent.imageAlignSections.map((el, index) => {
                return (
                  <TextWithAlignSideImage
                    title={el.title}
                    paragraphs={el.paragraphs}
                    image={imagesQuery.readMoreImages.edges[index]}
                    imageAlt={el.imageAlt}
                    imageAlign={el.alignImage}
                    key={index}
                  />
                );
              })}
              <ThreeImagesSection
                imagesQueryArray={imagesQuery.mainImages.edges}
                imagesAlts={imagesAlts}
              />
            </>
          </ReadMore>
        </div>
      </TabSectionContainer>
    </section>
  );
};

export default MethodsOfPhysiotherapySection;
