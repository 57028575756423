import React from 'react';
import PropTypes from 'prop-types';

const SectionHeaderText = ({ title, extraClass }) => {
  return (
    <h3
      className={
        extraClass ? `standard-section-header-text ${extraClass}` : 'standard-section-header-text'
      }
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
};

SectionHeaderText.propTypes = {
  title: PropTypes.string,
  extraClass: PropTypes.string,
};

export default SectionHeaderText;
