import React, { createContext, useReducer } from 'react';

import { INIT_STATE_MAIN_CONTEXT } from './initState';
import { mainReducer } from './reducer';

export const MainContext = createContext(null);

export const MainContextProvider = ({ children }) => {
  const [mainState, mainDispatch] = useReducer(mainReducer, INIT_STATE_MAIN_CONTEXT);
  const value = { state: mainState, dispatch: mainDispatch };

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
