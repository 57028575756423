import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const ThreeImagesSection = ({ imagesQueryArray, imagesAlts }) => {
  return (
    <div className="standard-section-container three-images-section col-3">
      {imagesQueryArray.map((img, index) => {
        return <Img key={index} fluid={img.node.childImageSharp.fluid} alt={imagesAlts[index]} />;
      })}
    </div>
  );
};

ThreeImagesSection.propTypes = {
  imagesQuery: PropTypes.array,
  imagesAlts: PropTypes.array,
};

export default ThreeImagesSection;
