import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { MainContextProvider } from '../context/MainContext';

import Layout from '../layout/Layout';
import MainBanner from '../components/sections/MainBanner';
import AboutUsSection from '../components/sections/AboutUsSection';
import MethodsOfPhysiotherapySection from '../components/sections/MethodsOfPhysiotherapySection';
import FAQSection from '../components/sections/FAQSection';
import MakeAnAppoitmentSection from '../components/sections/MakeAnAppoitmentSection';
import PricingSection from '../components/sections/PricingSection';
import ContactSection from '../components/sections/ContactSection';

const IndexPage = () => {
  const configData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
          description
        }
      }
    }
  `);

  return (
    <MainContextProvider>
      <Layout>
        <main>
          <Helmet>
            <title>{configData.site.siteMetadata.title}</title>
            <meta name="canonical" content={configData.site.siteMetadata.siteUrl} />
            <meta name="description" content={configData.site.siteMetadata.description} />
          </Helmet>
          <MainBanner />
          <AboutUsSection />
          <MethodsOfPhysiotherapySection />
          <FAQSection />
          <MakeAnAppoitmentSection />
          <PricingSection />
          <ContactSection />
        </main>
      </Layout>
    </MainContextProvider>
  );
};

export default IndexPage;
