import { CONST_SECTION_IDS } from '../constants';

export const INIT_STATE_MAIN_CONTEXT = {
  openSectionTabs: {
    [CONST_SECTION_IDS.aboutUs]: false,
    [CONST_SECTION_IDS.methodsOfPhysiotherapy]: false,
    [CONST_SECTION_IDS.makeAnAppoitment]: false,
    [CONST_SECTION_IDS.pricing]: false,
    [CONST_SECTION_IDS.contact]: false,
  },
};
