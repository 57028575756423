import React from 'react';

import { CONST_SECTION_IDS } from '../../constants';

import StandardParagraph from '../standards/StandardParagraph';
import SectionHeaderText from '../standards/SectionHeaderText';
import TabSectionContainer from '../elements/TabSectionContainer';

import mainContent from '../../content/mainContent.yaml';

const PricingSection = () => {
  return (
    <section className="standard-section section-pricing" id={CONST_SECTION_IDS.pricing}>
      <TabSectionContainer
        sectionTitle={mainContent.pricingSection.title}
        openerSectionId={CONST_SECTION_IDS.pricing}
      >
        <div className="standard-container pricing-container">
          {mainContent.pricingSection.pricingItems.map((el, index) => {
            return (
              <div className="pricing-item" key={index}>
                <SectionHeaderText title={el.question} extraClass="color-gold-dark" />
                <StandardParagraph text={el.answer} />
              </div>
            );
          })}
        </div>
      </TabSectionContainer>
    </section>
  );
};

export default PricingSection;
