import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import { mainBanner as bannerContent } from '../../content/mainContent.yaml';

const MainBanner = () => {
  const mainImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "main-banner/main-banner-01.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section className="section-main-banner">
      <div className="main-banner-container">
        <Image fluid={mainImage.file.childImageSharp.fluid} alt={bannerContent.imageAlt} />
        <div className="quote-container">
          <div className="standard-container">
            <p
              className="quote-text"
              dangerouslySetInnerHTML={{ __html: bannerContent.quoteText }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainBanner;
