import React from 'react';
import PropTypes from 'prop-types';

const SectionStrip = React.forwardRef(({ title, handleClick }, ref) => {
  return (
    <div className="standard-container" ref={ref}>
      <div className="section-strip">
        <button
          onClick={handleClick}
          className="section-strip-button"
          dangerouslySetInnerHTML={{ __html: title }}
          aria-label="button"
        ></button>
      </div>
    </div>
  );
});

SectionStrip.propTypes = {
  title: PropTypes.string,
  handleClick: PropTypes.func,
};

export default SectionStrip;
