import React, { useContext, useEffect, useRef, useState } from 'react';
// import { AnchorLink } from 'gatsby-plugin-anchor-links'; // THERE IS ISSUE WITH FIRST CLICK - LIB DOESNT WORK CORRECTLY
import { Link } from 'gatsby';

import { MainContext } from '../../context/MainContext';
import CustomAnchorScrollTo from '../../components/utils/CustomAnchorScrollTo';
import { actionOpenSectionTab } from '../../context/actions';
import { CONST_SECTION_IDS } from '../../constants';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const headerRef = useRef(null);
  const mainContext = useContext(MainContext);

  const renderMenu = () => {
    // const menuItems = ['Terapeuta', 'Metody', 'Wizyta', 'Ceny', 'Kontakt'];
    const menuItems = [
      CONST_SECTION_IDS.aboutUs,
      CONST_SECTION_IDS.methodsOfPhysiotherapy,
      CONST_SECTION_IDS.makeAnAppoitment,
      CONST_SECTION_IDS.pricing,
      CONST_SECTION_IDS.contact,
    ];

    return menuItems.map((item) => {
      return (
        <li key={item} className="menu-item">
          {/* <AnchorLink
            to={`/#${item.toLowerCase()}`}
            title={item}
            className="menu-item-link"
            onAnchorLinkClick={() => {
              if (isMenuOpen) setIsMenuOpen(false);
            }}
          >
            {item}
          </AnchorLink> */}
          <CustomAnchorScrollTo
            to={`#${item}`}
            href={`/#${item}`}
            title={item}
            className="menu-item-link"
            onAnchorLinkClick={() => {
              if (isMenuOpen) setIsMenuOpen(false);
              mainContext.dispatch(actionOpenSectionTab({ tabId: item }));
            }}
          >
            {`${item.toUpperCase().slice(0, 1)}${item.slice(1)}`}
          </CustomAnchorScrollTo>
        </li>
      );
    });
  };

  useEffect(() => {
    if (window !== undefined && headerRef && headerRef?.current) {
      window.addEventListener('scroll', () => {
        if (window.scrollY >= 400) {
          if (!headerRef.current.classList.contains('v-small')) {
            headerRef.current.classList.add('v-small');
          }
        } else if (window.scrollY < 250) {
          if (headerRef.current.classList.contains('v-small')) {
            headerRef.current.classList.remove('v-small');
          }
        }
      });
    }
  }, [headerRef]);

  return (
    <header className="main-header" ref={headerRef} id="main-header">
      <div className="standard-container header-container">
        <div className="logo-container">
          <Link className="logo-company" to="/">
            <h1 className="logo-company-text">Fizjo z Pasją</h1>
          </Link>
          <h2 className="logo-company-owner">Mateusz Stefański</h2>
        </div>
        <button
          className={
            isMenuOpen ? 'hamburger hamburger--collapse is-active' : 'hamburger hamburger--collapse'
          }
          type="button"
          aria-label="Menu opener"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
        <nav className="main-menu-nav">
          <ul className="main-menu-list">{renderMenu()}</ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
