import React from 'react';
import PropTypes from 'prop-types';

const ReadMoreButton = ({ title, handleClick }) => {
  return (
    <div className="standard-button-container">
      <button onClick={handleClick} className="read-more-btn">
        {title}
      </button>
    </div>
  );
};

ReadMoreButton.propTypes = {
  title: PropTypes.string,
  handleOpenTab: PropTypes.func,
};

export default ReadMoreButton;
