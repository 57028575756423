import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import SectionStrip from './SectionStrip';
import SectionContent from './SectionContent';
// import ImagesForPhysiotherapy from '../adds/ImagesForPhysiotherapy';

import { MainContext } from '../../context/MainContext';
import { actionCloseSectionTab } from '../../context/actions';

export const ANIMATION_STATES = {
  open: 'open',
  close: 'close',
};

const TabSectionContainer = ({
  sectionTitle,
  isOpenOnStart,
  customized,
  openerSectionId,
  children = null,
}) => {
  const sectionStripRef = useRef();
  const [actionTrigger, setActionTrigger] = useState(isOpenOnStart);
  const [actionInProgress, setActionInProgress] = useState(false);
  const mainContext = useContext(MainContext);

  useEffect(() => {
    if (actionInProgress) {
      setTimeout(() => {
        setActionInProgress(false);
      }, 555);
    }
  }, [actionInProgress]);

  useEffect(() => {
    if (mainContext.state.openSectionTabs[openerSectionId] && !actionTrigger) {
      setActionTrigger(true);
      setActionInProgress(true);
      mainContext.dispatch(actionCloseSectionTab({ tabId: openerSectionId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.state]);

  function handleClick() {
    if (!actionInProgress) {
      setActionTrigger(!actionTrigger);
      setActionInProgress(true);
      if (!actionTrigger) {
        if (typeof window !== 'undefined') {
          setTimeout(() => {
            window.scrollTo({
              top: sectionStripRef.current.offsetTop,
              left: 0,
              behavior: 'smooth',
            });
          }, 600);
          // window.scrollTo({
          //   top: sectionStripRef.current.offsetTop,
          //   left: 0,
          //   behavior: 'smooth',
          // });
        }
      }
    }
  }

  // function renderCustomized() {
  //   if (customized === 'physiotherapy') {
  //     return <ImagesForPhysiotherapy />;
  //   } else {
  //     return null;
  //   }
  // }

  return (
    <>
      <SectionStrip title={sectionTitle} handleClick={() => handleClick()} ref={sectionStripRef} />
      {/* renderCustomized() */}
      <SectionContent isOpenOnStart={isOpenOnStart} actionTrigger={actionTrigger}>
        {children}
      </SectionContent>
    </>
  );
};

TabSectionContainer.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  openerSectionId: PropTypes.string,
  isOpenOnStart: PropTypes.bool,
  customized: PropTypes.string,
};

TabSectionContainer.defaultProps = {
  isOpenOnStart: false,
  openerSectionId: undefined,
};

export default TabSectionContainer;
