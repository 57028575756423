import React from 'react';
import Img from 'gatsby-image';

import StandardParagraph from '../standards/StandardParagraph';

const TextWithAlignSideImage = ({ title, paragraphs, image, imageAlt, imageAlign }) => {
  return (
    <div className={`text-with-align-side-image-container image-${imageAlign} clearfix`}>
      <Img className="image" fluid={image.node.childImageSharp.fluid} alt={imageAlt} />
      <h2 className="header-text" dangerouslySetInnerHTML={{ __html: title }} />
      <div className="paragraphs-container">
        {paragraphs.map((el, index) => {
          return <StandardParagraph text={el} key={index} />;
        })}
      </div>
    </div>
  );
};

export default TextWithAlignSideImage;
