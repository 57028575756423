import React, { useRef } from 'react';

import { customGetCookie, customSetCookie } from '../../utils';

const COOKIES_POLICY_ACCEPT = 'fizjozpasjaCookiesPolicy20220120';

const CookiesInfoAlert = () => {
  const cookiesBoxRef = useRef();

  function closeCookiesBox() {
    if (cookiesBoxRef && cookiesBoxRef.current) {
      customSetCookie(COOKIES_POLICY_ACCEPT, new Date().toISOString(), '/', 365);
      cookiesBoxRef.current.remove();
    }
  }

  function renderAlertBox() {
    if (customGetCookie(COOKIES_POLICY_ACCEPT)) return null;

    return (
      <div className="cookies-info-alert-box" ref={cookiesBoxRef}>
        <div className="cookies-info-alert-box-content">
          <p className="cookies-info-alert-box-text">
            Korzystanie z serwisu oznacza akceptację
            <a
              href="/docs/polityka-prywatnosci-i-pliki-cookies.pdf"
              target="_blank"
              title="Polityk Cookies i Polityka Prywatności"
            >
              polityki cookies i polityki prywatności
            </a>
            .
          </p>
        </div>
        <button
          className="cookies-info-alert-box-close-btn"
          onClick={() => closeCookiesBox()}
          aria-label="Close"
        ></button>
      </div>
    );
  }

  return renderAlertBox();
};

export default CookiesInfoAlert;
