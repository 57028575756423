import React, { useReducer, useState, useRef } from 'react';

import SectionHeaderText from '../standards/SectionHeaderText';
import Loader from './Loader';

import formapp from '../../api/formapp';
import { validateEmail } from '../../utils';

const formDataStateInit = {
  name: '',
  phone: '',
  email: '',
  message: '',
  agreement: false,
  errorFields: [],
};

const SET_NAME_ACTION = 'SET_NAME';
const SET_PHONE_ACTION = 'SET_PHONE';
const SET_EMAIL_ACTION = 'SET_EMAIL';
const SET_MESSAGE_ACTION = 'SET_MESSAGE';
const SET_AGREEMENT_ACTION = 'SET_AGREEMENT';
const SET_ERROR_FIELD = 'SET_FIELD_ERROR';

function setNameAction(name) {
  return { type: SET_NAME_ACTION, payload: name };
}

function setPhoneAction(phone) {
  return { type: SET_PHONE_ACTION, payload: phone };
}

function setEmailAction(email) {
  return { type: SET_EMAIL_ACTION, payload: email };
}

function setMessageAction(message) {
  return { type: SET_MESSAGE_ACTION, payload: message };
}

function setAgreementAction(agreement) {
  return { type: SET_AGREEMENT_ACTION, payload: agreement };
}

function setErrorField(fieldName) {
  return { type: SET_ERROR_FIELD, payload: fieldName };
}

function addIfNotExists(array, item) {
  if (array.indexOf(item) === -1) {
    array.push(item);
  }

  return array;
}

function removeIfExists(array, item) {
  const indexOf = array.indexOf(item);
  if (indexOf !== -1) {
    array.splice(indexOf, 1);
  }

  return array;
}

function formDataReducer(state, action) {
  let newErrorFields = state.errorFields;
  switch (action.type) {
    case SET_NAME_ACTION:
      if (action.payload === '') {
        newErrorFields = addIfNotExists(newErrorFields, 'name');
      } else {
        newErrorFields = removeIfExists(newErrorFields, 'name');
      }
      return { ...state, name: action.payload, errorFields: newErrorFields };
    case SET_PHONE_ACTION:
      if (action.payload === '') {
        newErrorFields = addIfNotExists(newErrorFields, 'phone');
      } else {
        newErrorFields = removeIfExists(newErrorFields, 'phone');
        newErrorFields = removeIfExists(newErrorFields, 'email');
      }
      return { ...state, phone: action.payload, errorFields: newErrorFields };
    case SET_EMAIL_ACTION:
      if (action.payload === '') {
        newErrorFields = addIfNotExists(newErrorFields, 'email');
      } else {
        newErrorFields = removeIfExists(newErrorFields, 'email');
        newErrorFields = removeIfExists(newErrorFields, 'phone');
      }
      return { ...state, email: action.payload, errorFields: newErrorFields };
    case SET_MESSAGE_ACTION:
      if (action.payload === '') {
        newErrorFields = addIfNotExists(newErrorFields, 'message');
      } else {
        newErrorFields = removeIfExists(newErrorFields, 'message');
      }
      return { ...state, message: action.payload, errorFields: newErrorFields };
    case SET_AGREEMENT_ACTION:
      if (state.agreement) {
        newErrorFields = addIfNotExists(newErrorFields, 'agreement');
      } else {
        newErrorFields = removeIfExists(newErrorFields, 'agreement');
      }
      return { ...state, agreement: !state.agreement, errorFields: newErrorFields };
    case SET_ERROR_FIELD:
      newErrorFields = addIfNotExists(newErrorFields, action.payload);
      return { ...state, errorFields: newErrorFields };
    default:
      return state;
  }
}

const FORM_STATES = {
  form: 'form',
  sending: 'sending',
  sent: 'sent',
  error: 'error',
};

const ContactForm = () => {
  const [formDataState, formDataDispatch] = useReducer(formDataReducer, formDataStateInit);
  const [formState, setFormState] = useState(FORM_STATES.form);
  const nnameRef = useRef();
  const _hpRef = useRef();
  const cvalRef = useRef();
  const formNameRef = useRef();

  const cvalValue = process.env.GATSBY_CONTACT_FORM_CVAL_VALUE;

  function validateForm() {
    let isValid = true;
    // Name
    if (formDataState.name.length === 0) {
      formDataDispatch(setErrorField('name'));
      isValid = false;
    }

    // Email & Phone
    if (formDataState.phone.length === 0 && formDataState.email.length === 0) {
      formDataDispatch(setErrorField('email'));
      formDataDispatch(setErrorField('phone'));
      isValid = false;
    }

    // Email
    if (formDataState.email.length !== 0 && !validateEmail(formDataState.email)) {
      formDataDispatch(setErrorField('email'));
      isValid = false;
    }

    // Phone
    // If email is empty && phone is not empty && invalid phone regexp
    if (
      formDataState.email.length === 0 &&
      formDataState.phone.length !== 0 &&
      !formDataState.phone.match(/^\+?([0-9]*\s*).{9,15}/)
    ) {
      formDataDispatch(setErrorField('phone'));
      isValid = false;
    }

    // Message
    if (formDataState.message.length === 0) {
      formDataDispatch(setErrorField('message'));
      isValid = false;
    }

    // Agreement
    if (formDataState.agreement !== true) {
      formDataDispatch(setErrorField('agreement'));
      isValid = false;
    }

    return isValid;
  }

  function handleSubmit(event) {
    setFormState(FORM_STATES.sending);
    event.preventDefault();
    if (validateForm()) {
      const formDataToSend = {
        name: formDataState.name,
        phone: formDataState.phone,
        email: formDataState.email,
        message: formDataState.message,
        agreement: formDataState.agreement,
      };
      formDataToSend.nname = nnameRef.current.value;
      formDataToSend._hp = _hpRef.current.value;
      formDataToSend.formName = formNameRef.current.value;
      if (cvalRef.current.value === '') {
        formDataToSend.cval = cvalValue;
      } else {
        formDataToSend.cval = cvalRef.current.value;
      }

      formapp
        .post('', formDataToSend)
        .then(function (response) {
          if (response.status === 200) {
            setFormState(FORM_STATES.sent);
          }
        })
        .catch(function (error) {
          setFormState(FORM_STATES.error);
        });
    } else {
      setFormState(FORM_STATES.form);
    }
  }

  function renderFormStatus() {
    switch (formState) {
      case FORM_STATES.sending:
        return (
          <div className="form-message-content">
            <Loader />
            <p className="form-message-text loading">Trwa wysyłanie danych...</p>
          </div>
        );
      case FORM_STATES.sent:
        return (
          <div className="form-message-content">
            <p className="form-message-text success">
              Dziękuję za wysłanie wiadomości.
              <br />
              Skontaktuję się z&nbsp;Państwem najszybciej jak to będzie możliwe.
            </p>
          </div>
        );
      case FORM_STATES.error:
        return (
          <div className="form-message-content">
            <p className="form-message-text error">
              Nastąpił błąd podczas wysyłki formularza.
              <br />
              Proszę odświeżyć stronę i&nbsp;spróbować ponownie
              <br />
              lub wysłać wiadomość na adres{' '}
              <a className="text-link" href="mailto:fizjozpasja@gmail.com">
                fizjozpasja@gmail.com
              </a>
            </p>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <SectionHeaderText title="Formularz zgłoszeniowy" />
      {renderFormStatus()}
      <div className={'form-content' + (formState !== FORM_STATES.form ? ' hidden' : '')}>
        <div className="inputs-container">
          <div
            className={
              'input-container' + (formDataState.errorFields.includes('name') ? ' error' : '')
            }
          >
            <label>
              Imię:
              <input
                type="text"
                className="input input-text input-name"
                placeholder="imię"
                name="name"
                onChange={(event) => {
                  formDataDispatch(setNameAction(event.target.value));
                }}
                value={formDataState.name}
              />
            </label>
            <p className="error-text">^ To pole nie może być puste!</p>
          </div>
          <div
            className={
              'input-container' + (formDataState.errorFields.includes('phone') ? ' error' : '')
            }
          >
            <label>
              Numer telefonu:
              <input
                type="tel"
                className="input input-text input-phone"
                placeholder="numer telefonu"
                name="phone"
                onChange={(event) => {
                  formDataDispatch(setPhoneAction(event.target.value));
                }}
                value={formDataState.phone}
              />
            </label>
            <p className="error-text">^ Podanie numeru telefonu lub adresu e-mail jest wymagane!</p>
          </div>
          <div
            className={
              'input-container' + (formDataState.errorFields.includes('email') ? ' error' : '')
            }
          >
            <label>
              Adres e-mail:
              <input
                type="email"
                className="input input-text input-email"
                placeholder="adres e-mail"
                name="email"
                onChange={(event) => {
                  formDataDispatch(setEmailAction(event.target.value));
                }}
                value={formDataState.email}
              />
            </label>
            <p className="error-text">^ Podanie numeru telefonu lub adresu e-mail jest wymagane!</p>
          </div>
          <div
            className={
              'input-container' + (formDataState.errorFields.includes('message') ? ' error' : '')
            }
          >
            <label>
              Wiadomość:
              <textarea
                name="message"
                className="input input-textarea input-message"
                placeholder="treść wiadomości (powód wizyty, dolegliwości)"
                onChange={(event) => {
                  formDataDispatch(setMessageAction(event.target.value));
                }}
                value={formDataState.message}
              />
            </label>
            <p className="error-text">^ To pole nie może być puste!</p>
          </div>
          <div
            className={
              'input-container input-agreement-container' +
              (formDataState.errorFields.includes('agreement') ? ' error' : '')
            }
          >
            <label>
              Zapoznałem się z klauzulą informacyjną odnośnie przetwarzania danych osobowych.
              <input
                type="checkbox"
                className="input-checkbox input-agreement"
                name="agreement"
                checked={formDataState.agreement}
                onChange={() => {
                  formDataDispatch(setAgreementAction());
                }}
                value={formDataState.agreement}
              />
              <div className="checkbox-icon" />
              <p className="error-text">^ Akceptacja klauzli jest obowiązkowa!</p>
            </label>
          </div>
          <div className="input-checker-container">
            <label>
              <input
                type="hidden"
                className="input input-text input-checker"
                name="nname"
                ref={nnameRef}
              />
            </label>
          </div>
          <div className="input-checker-container">
            <label>
              <input
                type="text"
                className="input input-text input-checker"
                placeholder=""
                name="_hp"
                ref={_hpRef}
              />
            </label>
          </div>
          <div className="input-checker-container">
            <label>
              <input
                type="text"
                className="input input-text input-checker"
                placeholder=""
                name="cval"
                ref={cvalRef}
              />
            </label>
          </div>
          <div className="input-checker-container">
            <label>
              <input
                type="hidden"
                className="input input-text input-checker"
                name="formName"
                value="ContactFormV1"
                ref={formNameRef}
              />
            </label>
          </div>
        </div>
        <div className="standard-button-container">
          <button
            type="submit"
            onClick={handleSubmit}
            className="contact-form-submit-btn"
            disabled={formState !== FORM_STATES.form}
          >
            {formState !== FORM_STATES.form ? 'Trwa wysyłanie danych...' : 'Wyślij'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
