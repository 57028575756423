import React from 'react';

import { CONST_SECTION_IDS } from '../../constants';

import StandardParagraph from '../standards/StandardParagraph';
import SectionHeaderText from '../standards/SectionHeaderText';
import ContactForm from '../elements/ContactForm';
import TabSectionContainer from '../elements/TabSectionContainer';

import fullContent from '../../content/mainContent.yaml';

const mainContent = fullContent.makeAnAppoitmentSection;

const MakeAnAppoitmentSection = () => {
  return (
    <section
      className="standard-section section-make-an-appoitment"
      id={CONST_SECTION_IDS.makeAnAppoitment}
    >
      <TabSectionContainer
        sectionTitle={mainContent.title}
        openerSectionId={CONST_SECTION_IDS.makeAnAppoitment}
      >
        <>
          <div className="standard-container make-an-appoitment-container">
            <SectionHeaderText title={mainContent.mainContent.title} />
            {mainContent.mainContent.paragraphs.map((el, index) => {
              return <StandardParagraph text={el} key={index} />;
            })}
            <h3
              className="list-title"
              dangerouslySetInnerHTML={{ __html: mainContent.requiredItemsList.title }}
            />
            <ul className="required-items-list">
              {mainContent.requiredItemsList.items.map((el, index) => {
                return (
                  <li className="list-item" dangerouslySetInnerHTML={{ __html: el }} key={index} />
                );
              })}
            </ul>
          </div>
          <section className="contact-form-section">
            <div className="standard-container contact-form-container">
              <ContactForm />
            </div>
          </section>
        </>
      </TabSectionContainer>
    </section>
  );
};

export default MakeAnAppoitmentSection;
