import React from 'react';
import PropTypes from 'prop-types';

const CustomAnchorScrollTo = ({
  to,
  href,
  title,
  children,
  className,
  headerOffset,
  scrollTopPadding,
  customOffset,
  onAnchorLinkClick,
}) => {
  const scrollTo = (event) => {
    event.preventDefault();
    if (typeof window === 'undefined') return;

    let headerOffsetHeight = 0;
    if (headerOffset) {
      const HEADER_ID = 'main-header';
      headerOffsetHeight = document.querySelector(`#${HEADER_ID}`).offsetHeight;
    }
    const toElement = document.querySelector(to);
    if (toElement) {
      const scrollToElementTopPosition = toElement.offsetTop;
      window.scrollTo({
        left: 0,
        top: scrollToElementTopPosition - headerOffsetHeight + scrollTopPadding + customOffset,
        behavior: 'smooth',
      });
    }

    if (onAnchorLinkClick !== undefined) {
      onAnchorLinkClick();
    }
  };

  return (
    <a title={title} className={className} href={href} onClick={scrollTo}>
      {children}
    </a>
  );
};

CustomAnchorScrollTo.defaultProps = {
  headerOffset: true,
  scrollTopPadding: -10,
  customOffset: 0,
  onAnchorLinkClick: undefined,
};

CustomAnchorScrollTo.propTypes = {
  to: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  scrollTopPadding: PropTypes.number,
  headerOffset: PropTypes.bool,
  customOffset: PropTypes.number,
  onAnchorLinkClick: PropTypes.func,
};

export default CustomAnchorScrollTo;
