import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { CONST_SECTION_IDS } from '../../constants';

import SectionHeaderText from '../standards/SectionHeaderText';
import StandardParagraph from '../standards/StandardParagraph';
import ThreeImagesSection from '../elements/ThreeImagesSection';
import TabSectionContainer from '../elements/TabSectionContainer';
import ReadMore from '../elements/ReadMore';

import { renderAchievements } from '../../utils';

import mainContent from '../../content/mainContent.yaml';

const AboutUsSection = () => {
  const imagesQuery = useStaticQuery(graphql`
    query {
      mainImages: allFile(
        sort: { fields: childImageSharp___sizes___originalName, order: ASC }
        filter: { relativeDirectory: { eq: "about-us" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section className="standard-section section-about-us" id={CONST_SECTION_IDS.aboutUs}>
      <TabSectionContainer
        sectionTitle={mainContent.aboutUsSection.title}
        isOpenOnStart={true}
        openerSectionId={CONST_SECTION_IDS.aboutUs}
      >
        <div className="standard-section standard-container">
          <SectionHeaderText title={mainContent.aboutUsSection.aboutUs.title} />
          <div className="standard-section-container col-3-with-separator">
            <StandardParagraph text={mainContent.aboutUsSection.aboutUs.paragraphs[0]} />
            <div className="separator"></div>
            <StandardParagraph text={mainContent.aboutUsSection.aboutUs.paragraphs[1]} />
            <div className="separator"></div>
            <StandardParagraph text={mainContent.aboutUsSection.aboutUs.paragraphs[2]} />
          </div>
          <ReadMore scrollToElement="#terapeuta">
            <SectionHeaderText title={mainContent.aboutUsSection.passedCourses.title} />
            <ul className="achievements-list">
              {renderAchievements(mainContent.aboutUsSection.passedCourses.arrayData)}
            </ul>
            <SectionHeaderText title={mainContent.aboutUsSection.conferences.title} />
            <ul className="achievements-list">
              {renderAchievements(mainContent.aboutUsSection.conferences.arrayData)}
            </ul>
            <SectionHeaderText title={mainContent.aboutUsSection.publications.title} />
            <ul className="achievements-list">
              {renderAchievements(mainContent.aboutUsSection.publications.arrayData)}
            </ul>
            <ThreeImagesSection
              imagesQueryArray={imagesQuery.mainImages.edges}
              imagesAlts={mainContent.aboutUsSection.images.alts}
            />
          </ReadMore>
        </div>
      </TabSectionContainer>
    </section>
  );
};

AboutUsSection.propTypes = {};

export default AboutUsSection;
