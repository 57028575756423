import React from 'react';
import GoogleMapReact from 'google-map-react';

import { CONST_SECTION_IDS } from '../../constants';

import TabSectionContainer from '../elements/TabSectionContainer';

import mainContent from '../../content/mainContent.yaml';

// INFO: In case of heavy use map only when section is opened.
const ContactSection = () => {
  const isSSR = typeof window === 'undefined';
  const latLng = {
    lat: 50.1689613167738,
    lng: 18.892044698704705,
  };

  function renderMap() {
    return (
      <div className="standard-container map-container">
        {!isSSR && (
          <div className="standard-container map-container">
            <div className="map-content">
              <div style={{ height: '100%', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyBTlBVurU6v-92vxtWNhO6PwMJLGEK_Gs4' }}
                  defaultCenter={latLng}
                  defaultZoom={16}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => {
                    return new maps.Marker({
                      position: { lat: latLng.lat, lng: latLng.lng },
                      map,
                      title: 'Fizjo Z Pasją',
                    });
                  }}
                ></GoogleMapReact>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <section className="standard-section section-contact" id={CONST_SECTION_IDS.contact}>
      <TabSectionContainer
        sectionTitle={mainContent.contactSection.title}
        openerSectionId={CONST_SECTION_IDS.contact}
      >
        {renderMap()}
      </TabSectionContainer>
    </section>
  );
};

export default ContactSection;
