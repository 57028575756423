import React from 'react';

import MobilePhoneIcon from '../../assets/svgs/mobile-phone.inline.svg';
import PinIcon from '../../assets/svgs/pin.inline.svg';
import EnvelopeIcon from '../../assets/svgs/envelope.inline.svg';
import ClockIcon from '../../assets/svgs/clock.inline.svg';
import FacebookIcon from '../../assets/svgs/facebook.inline.svg';

import fullContent from '../../content/mainContent.yaml';

const mainContent = fullContent.contactInfo;

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-contact-info-container">
        <div className="standard-container footer-contact-info-icons-container">
          <a
            className="footer-icon-item footer-icon-item-link"
            href={`tel:${mainContent.phoneNumber.split(' ').join('')}`}
          >
            <MobilePhoneIcon className="icon-svg mobile-phone-svg" />
            <p className="footer-text">{mainContent.phoneNumber}</p>
          </a>
          <a
            className="footer-icon-item footer-icon-item-link"
            href="https://www.google.com/maps/place/%C5%BBwirki+i+Wigury+32,+43-190+Miko%C5%82%C3%B3w/@50.168872,18.889856,17z"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PinIcon className="icon-svg pin-svg" />
            <p
              className="footer-text"
              dangerouslySetInnerHTML={{ __html: mainContent.address }}
            ></p>
          </a>
          <a
            className="footer-icon-item footer-icon-item-link"
            href={`mailto:${mainContent.email}`}
          >
            <EnvelopeIcon className="icon-svg envelope-svg" />
            <p className="footer-text">{mainContent.email}</p>
          </a>
          <div className="footer-icon-item">
            <ClockIcon className="icon-svg clock-svg" />
            <p
              className="footer-text"
              dangerouslySetInnerHTML={{ __html: mainContent.openingTime }}
            ></p>
          </div>
          <a
            className="footer-icon-item footer-icon-item-link"
            href={mainContent.facebookUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className="icon-svg facebook-svg" />
            <p className="footer-text">{mainContent.facebookName}</p>
          </a>
        </div>
      </div>
      <div className="footer-contact-info-adds-container">
        <div className="standard-container">
          <p className="author-info">
            Created by{' '}
            <a
              className="author-link"
              href="https://reklama-bielsko.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ARTKOMP-IDEAART www.artkomp.pl
            </a>{' '}
            | All rights reserved &copy; {new Date().getUTCFullYear()} |{' '}
            <a
              href="/docs/polityka-prywatnosci-i-pliki-cookies.pdf"
              className="text-link"
              target="_blank"
              title="Polityk Cookies i Polityka Prywatności"
            >
              Polityka prywatności
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
