import React from 'react';

export function renderAchievements(achievementsMap) {
  return achievementsMap.map(({ date, description }, index) => {
    return (
      <li className="achievement-item" key={index}>
        <span className="achievement-date">{date}</span>
        <span className="achievement-description">{description}</span>
      </li>
    );
  });
}
