import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

const SectionContent = ({ isOpenOnStart, actionTrigger, children }) => {
  const tabContentRef = useRef();
  const [tabState, setTabState] = useState(false);

  useEffect(() => {
    setTabState(actionTrigger);
  }, [actionTrigger]);

  useEffect(() => {
    if (tabContentRef?.current) {
      const tabHeight = tabContentRef.current.offsetHeight;

      if (tabState) {
        tabContentRef.current.style.maxHeight = '0px';
        setTimeout(() => {
          tabContentRef.current.style.maxHeight = `${tabHeight}px`;
        }, 5);
        setTimeout(() => {
          tabContentRef.current.style.maxHeight = 'none';
        }, 550);
      } else {
        tabContentRef.current.style.maxHeight = `${tabHeight}px`;
        setTimeout(() => {
          tabContentRef.current.style.maxHeight = '0px';
        }, 5);
        setTimeout(() => {
          tabContentRef.current.style.maxHeight = 'none';
        }, 550);
      }
    }
  }, [tabState]);

  return (
    <CSSTransition
      in={tabState}
      timeout={500}
      className={'tab-section' + (isOpenOnStart ? ' active-on-start' : '')}
    >
      <div>
        <div className="tab-section-content" ref={tabContentRef}>
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

export default SectionContent;
