import { OPEN_SECTION_TAB_ACTION, CLOSE_SECTION_TAB_ACTION } from './types';

export const actionOpenSectionTab = ({ tabId }) => {
  return {
    type: OPEN_SECTION_TAB_ACTION,
    payload: {
      tabId,
    },
  };
};

export const actionCloseSectionTab = ({ tabId }) => {
  return {
    type: CLOSE_SECTION_TAB_ACTION,
    payload: {
      tabId,
    },
  };
};
