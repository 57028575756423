import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ReadMoreButton from './ReadMoreButton';

const ReadMore = ({ children, scrollToElement }) => {
  const [isOpenReadMore, setIsOpenReadMore] = useState(false);
  const readMoreContentRef = useRef();

  function renderButton() {
    return (
      <ReadMoreButton
        title={'Czytaj ' + (isOpenReadMore ? 'mniej' : 'więcej')}
        handleClick={() => setIsOpenReadMore(!isOpenReadMore)}
      />
    );
  }

  function renderSecondReadLessButton() {
    if (!isOpenReadMore) return null;

    return renderButton();
  }

  useEffect(() => {
    if (readMoreContentRef?.current) {
      if (isOpenReadMore) {
        readMoreContentRef.current.classList.remove('hidden');
      } else {
        if (!readMoreContentRef.current.classList.contains('hidden')) {
          readMoreContentRef.current.classList.add('hidden');
          if (typeof window !== 'undefined' && scrollToElement) {
            window.scrollTo({
              top: document.querySelector(scrollToElement).offsetTop - 80,
              left: 0,
              behavior: 'smooth',
            });
          }
        }
      }
    }
  }, [isOpenReadMore, scrollToElement]);
  return (
    <>
      {renderSecondReadLessButton()}
      <div className="read-more-content hidden" ref={readMoreContentRef}>
        {children}
      </div>
      {renderButton()}
    </>
  );
};

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  scrollToElement: PropTypes.string,
};

export default ReadMore;
