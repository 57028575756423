import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import StandardParagraph from '../standards/StandardParagraph';
import ThreeImagesSection from '../elements/ThreeImagesSection';
import TabSectionContainer from '../elements/TabSectionContainer';

import mainContent from '../../content/mainContent.yaml';

const FAQSection = () => {
  const imagesQuery = useStaticQuery(graphql`
    query {
      mainImages: allFile(
        sort: { fields: childImageSharp___sizes___originalName, order: ASC }
        filter: { relativeDirectory: { eq: "faq" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const imagesAlts = mainContent.faqSection.images.alts;

  function renderFAQItems() {
    return mainContent.faqSection.faqItems.map((el, index) => {
      return (
        <div className="faq-item" key={index}>
          <h2
            className="faq-item-question-text"
            dangerouslySetInnerHTML={{ __html: el.question }}
          />
          <StandardParagraph text={el.answer} />
        </div>
      );
    });
  }

  return (
    <section className="standard-section section-faq">
      <TabSectionContainer sectionTitle={mainContent.faqSection.title}>
        <div className="standard-container faq-container">
          {renderFAQItems()}
          <ThreeImagesSection
            imagesQueryArray={imagesQuery.mainImages.edges}
            imagesAlts={imagesAlts}
          />
        </div>
      </TabSectionContainer>
    </section>
  );
};

export default FAQSection;
