import React from 'react';

import MobilePhoneIcon from '../../assets/svgs/mobile-phone.inline.svg';
import EnvelopeIcon from '../../assets/svgs/envelope.inline.svg';
import FacebookIcon from '../../assets/svgs/facebook.inline.svg';

import mainContent from '../../content/mainContent.yaml';

const contactInfoContent = mainContent.contactInfo;

const HeaderBar = () => {
  return (
    <div className="header-bar">
      <div className="standard-container header-bar-container">
        <a
          className="info-element info-element-mobile-phone"
          href={`tel:${contactInfoContent.phoneNumber.split(' ').join('')}`}
        >
          <MobilePhoneIcon className="icon-svg" />
          <p className="info-text">{contactInfoContent.phoneNumber}</p>
        </a>
        <a className="info-element info-element-facebook" href={contactInfoContent.facebookUrl}>
          <FacebookIcon className="icon-svg" />
          <p className="info-text">{contactInfoContent.facebookName}</p>
        </a>
        <a
          className="info-element info-element-envelope"
          href={`mailto:${contactInfoContent.email}`}
        >
          <EnvelopeIcon className="icon-svg" />
          <p className="info-text">{contactInfoContent.email}</p>
        </a>
      </div>
    </div>
  );
};

export default HeaderBar;
