import React from 'react';
import PropTypes from 'prop-types';

const StandardParagraph = ({ text, extraClass }) => {
  return (
    <p
      className={extraClass ? `standard-paragraph ${extraClass}` : 'standard-paragraph'}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

StandardParagraph.propTypes = {
  text: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
};

export default StandardParagraph;
