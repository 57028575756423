import React from 'react';
import PropTypes from 'prop-types';

import '../assets/scss/main.scss';

import Header from './parts/Header';
import Footer from './parts/Footer';
import HeaderBar from '../components/elements/HeaderBar';
import CookiesInfoAlert from '../components/elements/CookiesInfoAlert';

const Layout = ({ children }) => {
  return (
    <>
      <HeaderBar />
      <Header />
      {children}
      <CookiesInfoAlert />
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
