import { OPEN_SECTION_TAB_ACTION, CLOSE_SECTION_TAB_ACTION } from './types';

export function mainReducer(state, action) {
  switch (action.type) {
    case OPEN_SECTION_TAB_ACTION: {
      const openSectionTabs = state.openSectionTabs;
      openSectionTabs[action.payload.tabId] = true;
      return { ...state, openSectionTabs };
    }
    case CLOSE_SECTION_TAB_ACTION: {
      const openSectionTabs = state.openSectionTabs;
      openSectionTabs[action.payload.tabId] = false;
      return { ...state, openSectionTabs };
    }
    default: {
      return state;
    }
  }
}
